/* Base Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
  // pointer-events: none;
}

html {
  box-sizing: inherit;
}

body {
  background-color: #000;
}

/* Fonts */
@font-face {
  font-family: 'Raleway';
  src: url(../assets/fonts/Raleway/Raleway-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Raleway';
  src: url(../assets/fonts/Raleway/Raleway-SemiBold.ttf) format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Raleway';
  src: url(../assets/fonts/Raleway/Raleway-Medium.ttf) format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Raleway';
  src: url(../assets/fonts/Raleway/Raleway-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Sen';
  src: url(../assets/fonts/Sen/Sen-Bold.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Sen';
  src: url(../assets/fonts/Sen/Sen-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

.loader {
  transition: all 1s cubic-bezier(0.83, 0, 0.17, 1);
  transition-delay: 3.5s;
  & h1,
  p,
  button {
    transition: all 1s cubic-bezier(0.83, 0, 0.17, 1);
  }

  & svg {
    transform: scale(0);
    top: 5rem;
    position: relative;
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  }
}

@screen xl {
  .loader svg {
    top: 8rem;
  }

  .hide {
    & svg {
      transform: scale(1.25);
      transition-delay: 2s;
    }
  }
}

#root {
  overflow: hidden;
  position: fixed;
}

#root.reset {
  overflow-x: hidden;
  position: unset;
}

.loader2 {
  transition-delay: 4.1s;
}

.clear {
  clear: both;
  content: '';
}

.loader3 {
  transition-delay: 10s;
}

.hide {
  & svg {
    transform: scale(1);
    transition-delay: 2s;
  }
  & h1 {
    transform: translateY(-10rem);
  }
  & p {
    transform: translateY(-10rem);
  }

  & button {
    width: 0;
    padding: 0;
    transition-delay: 0.6s;
    border: none;

    & span {
      visibility: hidden;
      transition-delay: 1s;
    }
  }
}

.btn:active {
  transform: translateY(0.3rem), scale(1.8);
  transition: all 0.3s;
}

.header::before {
  position: absolute;
  content: '';
  width: 70vw;
  height: 100%;
  background-image: url(../assets/svgs/Vector.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  top: 0;
  right: 0;
  z-index: -1;
  display: none;
}

.carousel::before {
  position: absolute;
  content: '';
  top: -0.1rem;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
  width: 100%;
  height: 79%;
  z-index: -1;
  left: 0;
}

.carousel .flickity-viewport {
  background: #000;
}

.canva canvas {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

@screen lg {
  .header::before {
    display: block;
  }

  .text-line::before {
    width: 35rem !important;
  }
}

@screen 2xl {
  .header::before {
    width: 75vw;
  }
}

.cursor {
  position: fixed;
  pointer-events: none;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.404);
  border: 3px solid #000;
}

.flickity-button {
  display: none !important;
}

.circle {
  --n: 6;
  --d: 12s;
  width: 486px;
  height: 486px;
  transform: translateY(-5rem);
}

.circle-container {
  width: 320px;
  height: 320px;
  background: rgba(69, 67, 67, 0.47);
  border: 3px solid #fff;
}

.circle-2 {
  --n: 2;
  --d: 6s;
  width: 200px;
  height: 200px;
  transform: translateY(1rem);
}

.circle,
.circle-2 {
  margin: 40px auto;
  border: 3px solid #fff;
  display: grid;
  grid-template-columns: 30px;
  grid-template-rows: 30px;
  place-content: center;
  border-radius: 50%;
}

.circle-2 .item {
  grid-area: 1/1;
  text-align: center;
  animation: spin2 9s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(100px) rotate(0);
}

.item {
  grid-area: 1/2;
  text-align: center;
  margin-right: 2rem;
  animation: spin 12s var(--d, 0s) linear infinite;
  transform: rotate(0) translate(243px) rotate(0);
}

.item:nth-child(1) {
  animation-delay: calc(-0 * var(--d) / var(--n));
}
.item:nth-child(2) {
  animation-delay: calc(-1 * var(--d) / var(--n));
}
.item:nth-child(3) {
  animation-delay: calc(-2 * var(--d) / var(--n));
}
.item:nth-child(4) {
  animation-delay: calc(-3 * var(--d) / var(--n));
}
.item:nth-child(5) {
  animation-delay: calc(-4 * var(--d) / var(--n));
}
.item:nth-child(6) {
  animation-delay: calc(-5 * var(--d) / var(--n));
}
.item:nth-child(7) {
  animation-delay: calc(-6 * var(--d) / var(--n));
}
.item:nth-child(8) {
  animation-delay: calc(-7 * var(--d) / var(--n));
}
.item:nth-child(9) {
  animation-delay: calc(-8 * var(--d) / var(--n));
}

@keyframes spin {
  100% {
    transform: rotate(1turn) translate(243px) rotate(-1turn);
  }
}

@keyframes spin2 {
  100% {
    transform: rotate(-1turn) translate(100px) rotate(1turn);
  }
}

.comment-quote::before {
  position: absolute;
  content: '';
  width: 10rem;
  height: 8rem;
  background-image: url('../assets/svgs/quote-lg.svg');
  background-repeat: no-repeat;
  left: 0;
  top: -3.6rem;
  z-index: -1;
  background-size: contain;
}

.text-line::before {
  position: absolute;
  content: '';
  width: 20rem;
  height: 2px;
  left: 50%;
  top: 0.85rem;
  transform: translateX(-50%);
  background-color: #f2f2f2;
  z-index: -1;
}

.quicks {
  & img {
    transition: all 3s ease;
  }

  &:hover img {
    filter: grayscale(100%);
    transform: scale(1.3) rotate(10deg);
  }
}

.status-message {
  opacity: 0;
  text-align: center;
}
.status-loading {
  opacity: 1;
  color: #fff;
  text-align: center;
}
.success {
  opacity: 1;
  color: green;
  text-align: center;
}
.failure {
  opacity: 1;
  color: red;
  text-align: center;
}
